import React from "react"
import Test from "./Test"

import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import CardsTest from "./CardsTest"
import StartPage from "./StartPage"
import SessionPage from "./SessionPage"
import Stops from "./Stops"
import Cards from "./Cards"
import ForceLogin from "./ForceLogin"
import ForceLogout from "./ForceLogout"

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/:roomCode">
            <Route index element={<SessionPage />} />
            <Route path="stops" element={<Stops />} />
            <Route path="cards" element={<Cards />} />
            <Route path="forceLogin/:playerName" element={<ForceLogin />} />
          </Route>
          <Route path="/forceLogout" element={<ForceLogout />} />
          <Route path="/test" element={<Test />} />
          <Route path="/cards" element={<CardsTest />} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App

import React, { useState } from "react"
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"

const WHERES = {
  stops: "Stops",
  cards: "Cards",
}

const BottomNav = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const splitPath = pathname.split("/")
  const where = splitPath[splitPath.length - 1] // I long for splitPath[-1]

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={where}
        onChange={(event, newValue) => {
          navigate(
            `${splitPath.slice(0, splitPath.length - 1).join("/")}/${newValue}`
          )
        }}
      >
        {Object.entries(WHERES).map(([value, label]) => (
          <BottomNavigationAction key={value} value={value} label={label} />
        ))}
      </BottomNavigation>
    </Paper>
  )
}

export default BottomNav

import { useMutation, UseMutationOptions, useQuery } from "react-query"
import { SessionCard } from "./types/cards"
import { Player, Session } from "./types/player"
import axios, { AxiosError, AxiosResponse } from "axios"
import { useLocalPlayer } from "./hooks/useLocalPlayer"
import { Stop } from "./types/places"

interface ArgPlayerId {
  playerId: string
}
interface ArgCard {
  cardId: string
}

type ApiErrors<T> = {
  [K in keyof (T & { nonFieldErrors: unknown })]?: string
}

type MutationOptions<TData> = {
  onSuccess?: (res: AxiosResponse<TData>) => void
  onError?: (error: AxiosError<ApiErrors<TData>>) => void
}

function getWithPlayerId<T>(path: string, { playerId }: ArgPlayerId) {
  return axios.get<T>(`players/${playerId}/${path}/`)
}

function getCards({ playerId }: ArgPlayerId) {
  return getWithPlayerId<SessionCard[]>("cards", { playerId })
}

function getCompletedCards({ playerId }: ArgPlayerId) {
  return getWithPlayerId<SessionCard[]>("completed_cards", { playerId })
}

export function useCards({
  playerId,
  completed,
}: ArgPlayerId & { completed?: boolean }) {
  let queryKey = "cards"
  if (completed) {
    queryKey = "completedCards"
  }
  return useQuery(queryKey, () =>
    (completed ? getCompletedCards : getCards)({ playerId })
  )
}

export function useCardCompleted(options?: MutationOptions<Player>) {
  return useMutation(
    ({
      playerId,
      cardId,
      successful,
    }: ArgPlayerId & ArgCard & { successful: boolean }) =>
      axios.post<Player>(`players/${playerId}/card_done/`, {
        cardId,
        successful,
      }),
    options
  )
}

export function useTakeChance(options?: MutationOptions<Player>) {
  return useMutation(
    ({ playerId }: ArgPlayerId) =>
      axios.post<Player>(`players/${playerId}/take_a_chance/`),
    options
  )
}

export function useSubmitBlank(options?: MutationOptions<Player>) {
  return useMutation(
    ({
      playerId,
      cardId,
      newContent,
    }: ArgPlayerId & ArgCard & { newContent: string }) =>
      axios.post<Player>(`players/${playerId}/submit_new_content/`, {
        cardId,
        newContent,
      }),
    options
  )
}

export function useCreateSession(options?: MutationOptions<Session>) {
  return useMutation(() => axios.post<Session>("sessions/"), options)
}

export function useStartSession(options?: MutationOptions<Session>) {
  return useMutation(
    (roomCode: Session["roomCode"]) =>
      axios.post<Session>(`sessions/${roomCode}/start/`),
    options
  )
}

export function useSession(
  roomCode: Session["roomCode"],
  // must be nicer ways to do this
  options?: { enabled: boolean }
) {
  return useQuery(
    ["session", roomCode],
    () => axios.get<Session>(`sessions/${roomCode}`),
    options
  )
}

interface CreatePlayerArgs {
  sessionId: Session["id"]
  name: Player["name"]
}

export function useCreatePlayer(
  // must be nicer ways to do this
  options: MutationOptions<Player>
  // options: Omit<UseMutationOptions<AxiosResponse<Player, any>, unknown, string, unknown>, "mutationFn"> | undefined
) {
  // TData = unknown, TError = unknown, TVariables = void, TContext = unknown
  // mutation can only return TData
  return useMutation(({ sessionId, name }: CreatePlayerArgs) => {
    return axios.post<Player>("players/", {
      session: sessionId,
      name,
    })
  }, options)
}

interface ForceLoginArgs {
  roomCode: Session["roomCode"]
  playerName: Player["name"]
}

export function useForceLogin(
  // must be nicer ways to do this
  options?: MutationOptions<Player>
) {
  return useMutation(({ roomCode, playerName }: ForceLoginArgs) => {
    return axios.post<Player>(`sessions/${roomCode}/force_login/`, {
      playerName,
    })
  }, options)
}

export function useToggleReady(options: MutationOptions<Player>) {
  return useMutation((playerId: string) => {
    return axios.post<Player>(`players/${playerId}/ready/`)
  }, options)
}

export function useStops({ roomCode }: { roomCode: Session["roomCode"] }) {
  return useQuery("stops", () =>
    axios.get<Stop[]>(`sessions/${roomCode}/stops/`)
  )
}

export function useNextStop(options?: MutationOptions<Stop[]>) {
  return useMutation(
    (roomCode: Session["roomCode"]) =>
      axios.get<Stop[]>(`sessions/${roomCode}/next_stop/`),
    options
  )
}

import { useState } from "react"
import { Box, Button, Divider, Stack, TextField } from "@mui/material"
import { useCreateSession, useSession } from "../api"
import { useNavigate } from "react-router-dom"

const StartPage = () => {
  const navigate = useNavigate()

  const [roomCode, setRoomCode] = useState<string>("")

  const [sessionNotFound, setSessionNotFound] = useState<boolean>(false)

  const getSession = useSession(roomCode, { enabled: false })

  const createSession = useCreateSession({
    onSuccess: (res) => {
      navigate(`/${res.data.roomCode}`)
    },
  })

  const tryJoin = async () => {
    const { data: res, isError } = await getSession.refetch()

    if (isError) {
      setSessionNotFound(true)
    } else {
      navigate(`/${res?.data.roomCode}`)
    }
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => createSession.mutate()}
        >
          Create new session
        </Button>

        <Divider />

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <TextField
            variant="standard"
            size="medium"
            label="Room code"
            value={roomCode}
            onChange={(e) => {
              setSessionNotFound(false)
              setRoomCode(e.target.value.slice(0, 4).toUpperCase())
            }}
            error={sessionNotFound}
            helperText={sessionNotFound && "Session doesn't exist"}
          />
          <Button
            variant="contained"
            size="large"
            disabled={roomCode.length < 4}
            onClick={() => tryJoin()}
          >
            Join session
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default StartPage

import React from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useLocalPlayer } from "../hooks/useLocalPlayer"
import BottomNav from "./BottomNav"
import { useNextStop, useStops } from "../api"
import StopCard from "./StopCard"

const Stops = () => {
  const { roomCode } = useParams()
  const { playerId } = useLocalPlayer()

  if (!roomCode) {
    // shuts up TS
    throw new Error()
  }

  const { data: stopsRes, refetch: refetchStops } = useStops({ roomCode })

  const stopCount = stopsRes?.data?.length || 0

  const nextStop = useNextStop({
    onSuccess: (res) => {
      refetchStops()
    },
  })

  return (
    <Box
      sx={{
        // height: "100vh",
        width: "100vw",
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ marginBottom: "10vh" }}
      >
        <Typography variant="h3">Stops</Typography>

        {stopsRes?.data?.map((stop, i) => (
          <React.Fragment key={stop.id}>
            <StopCard stop={stop} />
            {stopCount > 1 && i === 0 && (
              <Button
                variant="contained"
                size="large"
                onClick={() => nextStop.mutate(roomCode)}
              >
                Next stop!
              </Button>
            )}
          </React.Fragment>
        ))}
      </Stack>

      <BottomNav />
    </Box>
  )
}

export default Stops

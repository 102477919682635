import { Stop } from "../types/places"
import { Card, CardContent, Divider, Link, Typography } from "@mui/material"

interface Props {
  stop: Stop
}

const darkTextPropertySets = ["Yellow", "Light Blue"]

const StopCard = ({ stop }: Props) => {
  const startTime = stop.startTime
    ? stop.startTime.slice(0, stop.startTime.length - 3)
    : "??"
  const endTime = stop.endTime
    ? stop.endTime.slice(0, stop.endTime.length - 3)
    : "??"

  const [primaryPub, ...backupPubs] = stop.pubs

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          minWidth: "70vw",
        }}
      >
        <CardContent
          sx={{
            backgroundColor: stop.propertySet.colour,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              color: darkTextPropertySets.includes(stop.propertySet.name)
                ? "black"
                : "white",
            }}
          >
            {stop.name}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            <Link href={primaryPub.mapRef} target="_blank">
              {primaryPub.name}
            </Link>
          </Typography>
          {backupPubs.length > 0 && (
            <>
              <Divider sx={{ margin: "20px 0" }} />
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Backups:
              </Typography>
              {backupPubs.map((pub) => (
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  <Link href={pub.mapRef} target="_blank">
                    {pub.name}
                  </Link>
                </Typography>
              ))}
            </>
          )}
        </CardContent>
        <Divider />
        <CardContent>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Arrive by: {startTime}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Stay for: {stop.duration} minutes
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Leave by: {endTime}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

export default StopCard

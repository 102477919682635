import { useCards } from "../api"

const bobId = "b9e45edd-a2b2-4f20-915f-f54f56c333d5"

const CardsTest = () => {
  const { data: res, isLoading, isError } = useCards({ playerId: bobId })

  return (
    <div>
      <h1>Cards:</h1>
      {isLoading || isError ? (
        <>
          {isLoading && <p>Loading...</p>}
          {isError && <p>Error!</p>}
        </>
      ) : (
        res?.data?.map((card) => (
          <p>
            {card.type}: {card.content}
          </p>
        ))
      )}
    </div>
  )
}

export default CardsTest

// interacts with localstorage to save / recover player id

const LS_KEY = "monappoly__playerId"

export const useLocalPlayer = () => {
  const playerId = localStorage.getItem(LS_KEY)

  const login = (playerId: string) => {
    localStorage.setItem(LS_KEY, playerId)
  }

  const logout = () => {
    localStorage.removeItem(LS_KEY)
  }

  return { playerId, loggedIn: playerId !== null, login, logout }
}

import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { redirect, useNavigate, useParams } from "react-router-dom"
import {
  useCreatePlayer,
  useSession,
  useStartSession,
  useToggleReady,
} from "../api"
import { useEffect, useState } from "react"
import { useLocalPlayer } from "../hooks/useLocalPlayer"
import theme from "../theme"

const SessionPage = () => {
  const { roomCode } = useParams()
  const navigate = useNavigate()

  const [playerName, setPlayerName] = useState<string>("")
  const [nameError, setNameError] = useState<string | null>(null)

  const { playerId, loggedIn, login } = useLocalPlayer()
  const [welcomeSnack, setWelcomeSnack] = useState<string | null>(null)

  if (!roomCode) {
    // shuts up TS
    throw new Error()
  }

  const {
    data: sessionRes,
    isLoading,
    refetch: refetchSession,
  } = useSession(roomCode)

  const session = sessionRes?.data

  useEffect(() => {
    if (session?.startedAt) {
      navigate(`/${session.roomCode}/stops`)
    }
  }, [session])

  const createPlayer = useCreatePlayer({
    onSuccess: (res) => {
      login(res.data.id)

      setWelcomeSnack(`Hey ${res.data.name}!`)
      setPlayerName("") // just for cleanliness

      refetchSession()
    },
    onError: (error) => {
      const detail = error?.response?.data

      if (
        detail?.nonFieldErrors?.[0] ===
        "The fields name, session must make a unique set."
      ) {
        setNameError("That name's already taken")
      } else {
        setNameError(JSON.stringify(detail))
      }
    },
  })

  const startSession = useStartSession({
    onSuccess: (res) => {
      refetchSession()
    },
  })

  const toggleReady = useToggleReady({
    onSuccess: (res) => {
      refetchSession()
    },
  })

  const allReady =
    (session?.players?.length || 0) > 0 &&
    session?.players.reduce((a, player) => a && player.ready, true)

  const handleWelcomeSnackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setWelcomeSnack(null)
  }

  return (
    <Box
      sx={{
        // height: "100vh",
        width: "100vw",
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <Typography variant="h3">{session?.roomCode}</Typography>
            {session?.players.map((player) => (
              <Card
                sx={{
                  minWidth: "70vw",
                  backgroundColor: player.ready
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                }}
              >
                <CardActionArea
                  disabled={player.id !== playerId}
                  onClick={() => {
                    toggleReady.mutate(player.id)
                  }}
                >
                  <CardContent>
                    {player.name}
                    {player.id === playerId && " (You)"}
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </>
        )}

        {!loggedIn && (
          <>
            <TextField
              variant="standard"
              size="medium"
              label="Name"
              value={playerName}
              onChange={(e) => {
                setNameError(null)
                setPlayerName(e.target.value)
              }}
              error={!!nameError}
              helperText={nameError}
            />
            <Button
              variant="contained"
              size="large"
              disabled={playerName.length == 0}
              onClick={() =>
                createPlayer.mutate({
                  sessionId: session?.id!,
                  name: playerName,
                })
              }
            >
              Create player
            </Button>
          </>
        )}
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ marginTop: 10 }}
      >
        <Button
          variant="contained"
          size="large"
          disabled={!allReady}
          onClick={() => startSession.mutate(roomCode)}
        >
          Start!
        </Button>
      </Stack>

      <Snackbar
        open={!!welcomeSnack}
        autoHideDuration={6000}
        onClose={handleWelcomeSnackClose}
      >
        <Alert
          onClose={handleWelcomeSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {welcomeSnack}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default SessionPage

import { Box, Button, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useLocalPlayer } from "../hooks/useLocalPlayer"
import { useCards, useTakeChance } from "../api"
import React from "react"
import CardCard from "./CardCard"
import BottomNav from "./BottomNav"

const Cards = () => {
  const { roomCode } = useParams()
  const { playerId } = useLocalPlayer()

  if (!roomCode || !playerId) {
    throw new Error()
  }

  const { data: cardsRes, refetch: refetchCards } = useCards({ playerId })
  const { data: completedCardsRes, refetch: refetchCompletedCards } = useCards({
    playerId,
    completed: true,
  })

  const refetch = () => {
    refetchCards()
    refetchCompletedCards()
  }

  const takeChance = useTakeChance({
    onSuccess: (res) => {
      refetch()
    },
  })

  return (
    <Box
      sx={{
        // height: "100vh",
        width: "100vw",
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ marginBottom: "10vh" }}
      >
        <Typography variant="h3">Cards</Typography>

        {cardsRes?.data?.map((card, i) => (
          <React.Fragment key={card.id}>
            <CardCard card={card} onCompleted={() => refetch()} />
          </React.Fragment>
        ))}
      </Stack>

      <Button
        variant="outlined"
        size="large"
        color="error"
        sx={{
          marginBottom: 20,
        }}
        onClick={() => takeChance.mutate({ playerId })}
      >
        Take a chance
      </Button>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ marginBottom: "10vh" }}
      >
        <Typography variant="h5">Completed cards</Typography>

        {completedCardsRes?.data?.map((card, i) => (
          <React.Fragment key={card.id}>
            <CardCard card={card} completed />
          </React.Fragment>
        ))}
      </Stack>

      <BottomNav />
    </Box>
  )
}

export default Cards

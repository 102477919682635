import React, { useState } from "react"
import { useQuery } from "react-query"

import runtimeEnv from "@mars/heroku-js-runtime-env"
import { Piece } from "../types/player"
import { Pub, Stop } from "../types/places"
import styled from "@emotion/styled"

const env = runtimeEnv()

// TODO: move piece stuff into it's own file
function PieceImg({ piece }: { piece: Piece }) {
  return (
    <div
      style={{
        width: 240,
        height: 240,
        display: "flex",
        background: "white",
        marginBottom: 10,
      }}
    >
      <img
        src={`${BASE_URL}${piece.imageUrl}`}
        alt={piece.slug}
        style={{
          filter: "grayscale(100%)",
          margin: "auto",
        }}
      />
    </div>
  )
}

const BASE_URL = env.REACT_APP_BACKEND_API_END_POINT

const StyledTestApp = styled.div`
  text-align: center;
`

const StyledAppHeader = styled.header`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

function Test() {
  // { isLoading, error, data }
  const stopsQuery = useQuery<Stop[]>("stops", () =>
    fetch(`${BASE_URL}/stops/`).then((res) => res.json())
  )
  const pubsQuery = useQuery<Pub[]>("pubs", () =>
    fetch(`${BASE_URL}/pubs/`).then((res) => res.json())
  )
  const piecesQuery = useQuery<Piece[]>("pieces", () =>
    fetch(`${BASE_URL}/pieces/`).then((res) => res.json())
  )

  const [showStops, setShowStops] = useState<boolean>(false)
  const [showPubs, setShowPubs] = useState<boolean>(false)
  const [showPieces, setShowPieces] = useState<boolean>(false)

  return (
    <StyledTestApp>
      <StyledAppHeader>
        <h2
          onClick={() => {
            setShowStops(!showStops)
          }}
        >
          Stops {stopsQuery.data && `(${stopsQuery.data.length})`}
        </h2>
        {showStops && (
          <div>
            {stopsQuery.isLoading ? (
              "Loading..."
            ) : (
              <>
                {stopsQuery.data?.map((stop) => (
                  <div
                    key={stop.id}
                    style={{
                      background: stop.propertySet.colour,
                      width: 400,
                      height: 60,
                      marginTop: 20,
                      border: "black 2px",
                    }}
                  >
                    {stop.name}
                  </div>
                ))}
              </>
            )}
          </div>
        )}

        <h2
          onClick={() => {
            setShowPubs(!showPubs)
          }}
        >
          Pubs {pubsQuery.data && `(${pubsQuery.data.length})`}
        </h2>
        {showPubs && (
          <div>
            {pubsQuery.isLoading ? (
              "Loading..."
            ) : (
              <>
                {pubsQuery.data
                  ?.filter((pub) => pub.priority === 0)
                  .map((pub) => (
                    <p key={pub.id}>
                      <a href={pub.mapRef}>
                        {pub.name}
                        {/*@ {pub.stop.name}*/}
                      </a>
                    </p>
                  ))}
              </>
            )}
          </div>
        )}

        <h2
          onClick={() => {
            setShowPieces(!showPieces)
          }}
        >
          Pieces {piecesQuery.data && `(${piecesQuery.data.length})`}
        </h2>
        {showPieces &&
          (piecesQuery.isLoading
            ? "Loading..."
            : piecesQuery.data?.map((piece) => (
                <PieceImg key={piece.id} piece={piece} />
              )))}
      </StyledAppHeader>
    </StyledTestApp>
  )
}

export default Test

import { useNavigate, useParams } from "react-router-dom"
import { useLocalPlayer } from "../hooks/useLocalPlayer"
import { useForceLogin } from "../api"
import { Button } from "@mui/material"
import { useState } from "react"

const ForceLogin = () => {
  const { roomCode, playerName } = useParams()

  const navigate = useNavigate()

  const { login } = useLocalPlayer()

  const [failed, setFailed] = useState<boolean>(false)

  const forceLogin = useForceLogin({
    onSuccess: (res) => {
      login(res.data.id)
      navigate(`/${roomCode}`)
    },
    onError: (error) => {
      setFailed(true)
    },
  })

  return roomCode && playerName ? (
    <>
      <Button onClick={() => forceLogin.mutate({ roomCode, playerName })}>
        force login as {playerName} for {roomCode}
      </Button>
      {failed && <p>That didn't work</p>}
    </>
  ) : (
    <p>"something's gone wrong"</p>
  )
}

export default ForceLogin

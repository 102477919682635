import { useNavigate } from "react-router-dom"
import { useLocalPlayer } from "../hooks/useLocalPlayer"
import { Button } from "@mui/material"

const ForceLogout = () => {
  const navigate = useNavigate()

  const { logout } = useLocalPlayer()

  return (
    <>
      <Button
        onClick={() => {
          logout()
          navigate(`/`)
        }}
      >
        say goodbye?
      </Button>
    </>
  )
}

export default ForceLogout

import { SessionCard } from "../types/cards"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import theme from "../theme"
import React, { useState } from "react"
import { useCardCompleted, useSubmitBlank } from "../api"
import { useLocalPlayer } from "../hooks/useLocalPlayer"

interface Props {
  card: SessionCard
  onCompleted?: () => void
  completed?: boolean
}

const cardColours = {
  community: theme.palette.info.light,
  chance: theme.palette.error.light,
}

const cardTypes = {
  community: "Community Chest",
  chance: "Chance",
}

const CardCard = ({ card, onCompleted, completed }: Props) => {
  const { playerId } = useLocalPlayer()

  if (!playerId) {
    throw new Error()
  }

  const [newContent, setNewContent] = useState<string>("")

  const cardCompleted = useCardCompleted({
    onSuccess: (res) => {
      onCompleted && onCompleted()
    },
  })

  const submitNewContent = useSubmitBlank({
    onSuccess: (res) => {
      onCompleted && onCompleted()
    },
  })

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          width: "90vw",
        }}
      >
        <CardContent
          sx={{
            backgroundColor: cardColours[card.type],
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: 5 }}>
              {cardTypes[card.type]}
            </Typography>
            {card.createdBy && (
              <Typography variant="body2" sx={{ marginBottom: 5 }}>
                lovingly crafted by: {card.createdBy}
              </Typography>
            )}
          </Box>
          {card.isBlank ? (
            <TextField
              variant="standard"
              multiline
              rows={4}
              sx={{ width: "100%" }}
              placeholder="Do your worst"
              value={newContent}
              onChange={(e) => {
                setNewContent(e.target.value)
              }}
            />
          ) : (
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                marginBottom: 5,
              }}
            >
              {card.content}
            </Typography>
          )}
        </CardContent>
        {!completed && (
          <CardActions sx={{ justifyContent: "space-between" }}>
            {card.isBlank ? (
              <Button
                size="small"
                sx={{ marginLeft: "auto" }}
                disabled={newContent === ""}
                onClick={() => {
                  submitNewContent.mutate({
                    playerId,
                    cardId: card.id,
                    newContent,
                  })
                }}
              >
                Submit
              </Button>
            ) : (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    cardCompleted.mutate({
                      playerId,
                      cardId: card.id,
                      successful: false,
                    })
                  }}
                >
                  Failed
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    cardCompleted.mutate({
                      playerId,
                      cardId: card.id,
                      successful: true,
                    })
                  }}
                >
                  Completed
                </Button>
              </>
            )}
          </CardActions>
        )}
      </Card>
    </div>
  )
}

export default CardCard
